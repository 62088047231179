import styled from "styled-components"
import logo1 from "../../assets/images/brand/logo_1.svg";
import logo2 from "../../assets/images/brand/logo_2.svg";
import visionImg from "../../assets/images/brand/vision.png";
import gridBgImg from "../../assets/images/brand/grid_bg.png";
import colorImg from "../../assets/images/brand/color.svg";
import frameImg1 from "../../assets/images/brand/frame_1.png";
import frameImg2 from "../../assets/images/brand/frame_2.png";
import frameImg3 from "../../assets/images/brand/frame_3.png";
import frameImg4 from "../../assets/images/brand/frame_4.png";
import frameImg5 from "../../assets/images/brand/frame_5.png";
import interiorImg1 from "../../assets/images/brand/interior_1.png";
import interiorImg2 from "../../assets/images/brand/interior_2.png";
import interiorImg3 from "../../assets/images/brand/interior_3.png";
import interiorImg4 from "../../assets/images/brand/interior_4.png";
import main1 from "../../assets/images/home/main1.png";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation } from "swiper/modules";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import MetaTag from "../../layout/MetaTag";

const Container = styled.div`
    .logo-area {
        display:flex;gap:10%;align-items:center;justify-content:center;
        flex-wrap:wrap;
        img:nth-child(1) {width:423px;}
        img:nth-child(2) {height:166px;}
    }
    .color-area {display:flex;gap:10%;align-items:center;justify-content:center;}
    .color-item1 {display:block;border-radius:8px;background:url(${colorImg}) no-repeat center;width:282px;height:282px;background-color:#2B2B3D;background-size: 60%;background-position-x:56%;}
    .color-item2 {display:block;border-radius:8px;background:url(${colorImg}) no-repeat center;width:282px;height:282px;background-color:#7152EE;background-size: 60%;background-position-x:56%;}
    .color-item3 {display:block;border-radius:8px;background:url(${colorImg}) no-repeat center;width:282px;height:282px;background-color:#B474DF;background-size: 60%;background-position-x:56%;}
    .brand-desc {font-size:18px;margin-top:20px;line-height:1.5;}
    @media screen and (max-width: 1024px) {
        .logo-area img:nth-child(1) {width:300px;}
        .logo-area img:nth-child(2) {height:126px;}
    }
    @media screen and (max-width: 767px) {
        .logo-area {gap:40px;flex-direction:column;height:280px;}
        .logo-area img:nth-child(1) {width:240px;}
        .logo-area img:nth-child(2) {height:106px;}
        .frame-area {justify-content:center;}
        .brand-desc {font-size:16px;margin-top:14px;}
        .color-area {gap:14px;height:240px;}
        .color-item1, 
        .color-item2, 
        .color-item3 {width:90px;height:90px;}
        .frame-area {gap:34px 24px;}
        .frame-item img {margin-bottom:12px !important;height:130px !important;}
        .interior-slide {height:30vh !important;}
        .page-title2 {font-size:24px !important;margin-bottom:30px !important;}
        .page-text { word-break: unset !important; }
    }
    .swiper-button-next, 
    .swiper-button-prev {
        display: none;
    }
`;
const Vision = styled.div`
    border-radius:18px;overflow:hidden;
    background:url(${visionImg}) no-repeat center/cover;
    padding:60px 20px;text-align:center;
    strong {
        display:block;text-align:center;color:#fff;font-size:26px;
        font-weight:600;padding-bottom:10px;
        em {font-weight:100;}
    }
    span {
        display:block;text-align:center;color:#fff;font-weight:600;
        font-size:18px;
    }
    @media screen and (max-width: 767px) {
        span { font-size: 15px !important; }
    }
`;
const GridBg = styled.div`
    background:#fff url(${gridBgImg}) repeat;height:400px;
    border-radius: 18px;border: 1px solid #EDEDED;
`;
const FrameArea = styled.div`
    display:flex;justify-content:space-between;gap:30px 16px;flex-wrap:wrap;
    .frame-item {
        text-align:center;font-size:17px;
        img {margin-bottom:20px;height:244px;}
    }
`;
const SlideWrap = styled.div`
    position:relative;
    .interior-slide {
        border-radius:16px;height:520px;
        img {height:100%;width:100%;object-fit:cover;}
    }
    .interior-slide-prev {
        position:absolute; 
        left:-26px; top:50%; 
        display:flex; 
        align-items:center; 
        justify-content:center; 
        font-size:24px; 
        box-shadow:1px 1px 5.9px 0px rgba(0, 0, 0, 0.06); 
        transform:translateY(-50%); 
        width:52px; 
        height:52px; 
        border-radius:100px; 
        background-color:#fff; 
        z-index:1;
        cursor: pointer;
        &:hover {
            background-color:#343434; 
            color:#fff;
            transition: 0.3s all;
        }
    }
    .interior-slide-next {
        position:absolute; 
        right:-26px; top:50%; 
        display:flex; 
        align-items:center; 
        justify-content:center; 
        font-size:24px; 
        box-shadow:1px 1px 5.9px 0px rgba(0, 0, 0, 0.06); 
        transform:translateY(-50%); 
        width:52px; 
        height:52px; 
        border-radius:100px; 
        background-color:#fff; 
        z-index:1;
        cursor: pointer;
        &:hover {
            background-color:#343434; 
            color:#fff;
            transition: 0.3s all;
        }
    }
`;

export default function Brand() {
    const { t } = useTranslation();
    const prevRef = useRef();
    const nextRef = useRef();
    useEffect(() => {
        if (prevRef.current && nextRef.current) {
            const swiperInstance = document.querySelector('.interior-slide').swiper;
            swiperInstance.params.navigation.prevEl = prevRef.current;
            swiperInstance.params.navigation.nextEl = nextRef.current;
            swiperInstance.navigation.init();
            swiperInstance.navigation.update();
        }
    }, [prevRef, nextRef]);
    return (
        <>
            <MetaTag
                title="Photomag - Brand"
                description="Photomagで平凡な日常をマガジンのように素敵な瞬間に残してみましょう。"
                keywords="brand"
            />
            <Container className="container brand">
                <div className="page-header">
                    <div className="page-header-bg"><img src={main1} alt="Photomag brand logo" /></div>
                    <div className="inner">
                        <h2 className="page-title">{t("common.brand")}</h2>
                        <p className="page-text">{t("brandPage.title")}</p>
                    </div>
                </div>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.brandVision")}</h3>
                        <Vision>
                            <strong>Photo <em>X</em> Magazine</strong>
                            <span>{t("brandPage.visionContent")}</span>
                        </Vision>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.brandLogo")}</h3>
                        <GridBg className="logo-area">
                            <img src={logo1} alt="Photomag brand 로고1" />
                            <img src={logo2} alt="Photomag brand 로고2" />
                        </GridBg>
                        <p className="brand-desc">BI Identity : {t("brandPage.brandLogoEx")}</p>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.brandColor")}</h3>
                        <GridBg className="color-area">
                            <div className="color-item1"></div>
                            <div className="color-item2"></div>
                            <div className="color-item3"></div>
                        </GridBg>
                        <p className="brand-desc">Signature : {t("brandPage.brandColorEx")}</p>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.frames")}</h3>
                        <FrameArea className="frame-area">
                            <div className="frame-item">
                                <img src={frameImg1} alt="Photomag 4CUT (1+1)" />
                                <span>4CUT (1+1)</span>
                            </div>
                            <div className="frame-item">
                                <img src={frameImg2} alt="Photomag 6CUT (1+1)" />
                                <span>6CUT (1+1)</span>
                            </div>
                            <div className="frame-item">
                                <img src={frameImg3} alt="Photomag 4CUT (1+1)" />
                                <span>4CUT (1+1)</span>
                            </div>
                            <div className="frame-item">
                                <img src={frameImg4} alt="Photomag 4CUT (1+1)" />
                                <span>4CUT (1+1)</span>
                            </div>
                            <div className="frame-item">
                                <img src={frameImg5} alt="Photomag 5CUT (1+1)" />
                                <span>5CUT (1+1)</span>
                            </div>
                        </FrameArea>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.interior")}</h3>
                        <SlideWrap>
                            <Swiper
                                className="interior-slide"
                                speed={500}
                                slidesPerView={"auto"}
                                centeredSlides={true}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current,
                                }}
                                onBeforeInit={(swiper) => {
                                    swiper.params.navigation.prevEl = prevRef.current;
                                    swiper.params.navigation.nextEl = nextRef.current;
                                }}
                                modules={[Navigation]}
                            >
                                <div className="swiper-wrapper">
                                    <SwiperSlide><img src={interiorImg1} alt="Photomag brand slide1" /></SwiperSlide>
                                    <SwiperSlide><img src={interiorImg2} alt="Photomag brand slide2" /></SwiperSlide>
                                    <SwiperSlide><img src={interiorImg3} alt="Photomag brand slide3" /></SwiperSlide>
                                    <SwiperSlide><img src={interiorImg4} alt="Photomag brand slide4" /></SwiperSlide>
                                </div>
                            </Swiper>
                            <div ref={prevRef} className="interior-slide-prev">
                                <span className="material-icons-round">chevron_left</span>
                            </div>
                            <div ref={nextRef} className="interior-slide-next">
                                <span className="material-icons-round">chevron_right</span>
                            </div>
                        </SlideWrap>
                        <p className="brand-desc">Concept : {t("brandPage.interiorEx")}</p>
                    </div>
                </section>
            </Container>
        </>
    )
}