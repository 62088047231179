import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoImage,
	Autosave,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	CloudServices,
	Code,
	CodeBlock,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	// Heading,
	Highlight,
	HorizontalLine,
	HtmlComment,
	HtmlEmbed,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	Paragraph,
	SelectAll,
	// ShowBlocks,
	// SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Style,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	// TextPartLanguage,
	TextTransformation,
	// Title,
	TodoList,
	Underline,
	Undo
} from 'ckeditor5';

import translations from 'ckeditor5/translations/ko.js';

import 'ckeditor5/ckeditor5.css';

import './App.css';
import styled from 'styled-components';
import DOMPurify from 'isomorphic-dompurify';

const EditorWrap = styled.div`
	width: 100%;
	box-sizing: border-box;
	z-index: 1;
	margin-top: 2rem;
	.ck-reset_all {
		position: relative;
		z-index: 4 !important;
	}
  	.editor-container__editor {
		width: 100%;
		max-width: 1152px;
		min-width: 695px;
  	}
  	.ck-editor__editable {
		min-height: 600px;
		font-size: 14px;
  	}
  	@media (max-width: 1600px) {
		.ck-editor__editable {
			min-height: 400px;
		}
  	}
	.ck-editor__editable em, .ck-editor__editable i {
 	   font-style: italic !important;
	}
	strong u {
    	font-family: 'Pretendard-Bold';
	}
`;
const debounce = (func, delay) => {
	let timeout;
	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), delay);
	};
};

export default function App({ name, value, onChangeValue, disabled }) {
	const editorRef = useRef(null);
	const [editorWidth, setEditorWidth] = useState('100%');
	const sanitizedHTML = DOMPurify.sanitize(value);

	useEffect(() => {
		const handleResize = debounce(() => {
			const windowWidth = window.innerWidth;
			// console.log(windowWidth);

			if (windowWidth >= 1700) {
				setEditorWidth('1152px');
			} else if (windowWidth > 1500) {
				setEditorWidth('838.1px');
			} else {
				setEditorWidth('695px');
			}
		}, 100);

		// 초기 설정 및 이벤트 리스너 등록
		handleResize();
		window.addEventListener('resize', handleResize);

		// Cleanup 함수: 이벤트 리스너 해제
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const editorConfig = {
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				// 'sourceEditing',
				// 'showBlocks',
				'findAndReplace',
				// 'textPartLanguage',
				'|',
				// 'heading',
				'style',
				'|',
				'fontSize',
				'fontFamily',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'code',
				'|',
				'specialCharacters',
				'horizontalLine',
				'link',
				'insertImage',
				'insertTable',
				'highlight',
				'blockQuote',
				'codeBlock',
				'htmlEmbed',
				'|',
				'alignment',
				'|',
				'bulletedList',
				'numberedList',
				'todoList',
				'outdent',
				'indent'
			],
			shouldNotGroupWhenFull: false
		},
		plugins: [
			AccessibilityHelp,
			Alignment,
			Autoformat,
			AutoImage,
			Autosave,
			Base64UploadAdapter,
			BlockQuote,
			Bold,
			CloudServices,
			Code,
			CodeBlock,
			Essentials,
			FindAndReplace,
			FontBackgroundColor,
			FontColor,
			FontFamily,
			FontSize,
			FullPage,
			GeneralHtmlSupport,
			// Heading,
			Highlight,
			HorizontalLine,
			HtmlComment,
			HtmlEmbed,
			ImageBlock,
			ImageCaption,
			ImageInline,
			ImageInsert,
			ImageInsertViaUrl,
			ImageResize,
			ImageStyle,
			ImageTextAlternative,
			ImageToolbar,
			ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			LinkImage,
			List,
			ListProperties,
			Paragraph,
			SelectAll,
			// ShowBlocks,
			// SourceEditing,
			SpecialCharacters,
			SpecialCharactersArrows,
			SpecialCharactersCurrency,
			SpecialCharactersEssentials,
			SpecialCharactersLatin,
			SpecialCharactersMathematical,
			SpecialCharactersText,
			Strikethrough,
			Style,
			Table,
			TableCaption,
			TableCellProperties,
			TableColumnResize,
			TableProperties,
			TableToolbar,
			// TextPartLanguage, // 언어선택
			TextTransformation,
			// Title, // 제목
			TodoList,
			Underline,
			Undo
		],
		fontFamily: {
			options: ['default', 'Arial', '궁서체', '바탕', '돋움'],
			supportAllValues: true
		},
		fontSize: {
			options: [10, 12, 'default', 16, 18, 20, 22],
			supportAllValues: true
		},
		htmlSupport: {
			allow: [
				{
					name: /^.*$/,
					styles: true,
					attributes: true,
					classes: true
				},
			]
		},
		image: {
			toolbar: [
				'toggleImageCaption',
				'imageTextAlternative',
				'|',
				'imageStyle:inline',
				'imageStyle:wrapText',
				'imageStyle:breakText',
				'|',
				'resizeImage'
			]
		},
		language: 'ko',
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: 'https://',
			decorators: {
				toggleDownloadable: {
					mode: 'manual',
					label: 'Downloadable',
					attributes: {
						download: 'file'
					}
				}
			}
		},
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		placeholder: '최대 2048자까지 쓸 수 있습니다.',
		style: {
			definitions: [
				{
					name: 'Article category',
					element: 'h3',
					classes: ['category']
				},
				{
					name: 'Title',
					element: 'h2',
					classes: ['document-title']
				},
				{
					name: 'Subtitle',
					element: 'h3',
					classes: ['document-subtitle']
				},
				{
					name: 'Info box',
					element: 'p',
					classes: ['info-box']
				},
				{
					name: 'Side quote',
					element: 'blockquote',
					classes: ['side-quote']
				},
				{
					name: 'Marker',
					element: 'span',
					classes: ['marker']
				},
				{
					name: 'Spoiler',
					element: 'span',
					classes: ['spoiler']
				},
				{
					name: 'Code (dark)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-dark']
				},
				{
					name: 'Code (bright)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-bright']
				}
			]
		},
		table: {
			contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
		},
		translations: [translations],
	};

	return (
		<EditorWrap>
			<div className="editor-container editor-container_classic-editor editor-container_include-style">
				{/* ck-content 클래스를 사용하여 CKEditor의 스타일을 유지 */}
				<div className="editor-container__editor ck-content">
					{/* HTML 데이터를 직접 렌더링 */}
					<div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
				</div>
			</div>
		</EditorWrap>
	);
}
