import styled from "styled-components";
import subHeaderBg from "../../../assets/images/board/sub_header_bg.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClassicEditor from "../../../ckeditor5/src/App.jsx";
import { useEffect, useState } from "react";
import { callApi } from "../../../util/HttpService.jsx";
import { useTranslation } from "react-i18next";
import MetaTag from "../../../layout/MetaTag.js";

const Container = styled.div``;
const BoardViewWrap = styled.div`
    .board-title {width:100%; font-size:24px; padding-bottom:14px; border-bottom:1px solid #C6C6C6; font-weight:700;}
    .board-cts {width:100%; font-size:18px; padding:40px 0 24px; border-bottom:1px solid #C6C6C6; margin-bottom:14px; line-height:1.8;}
    .board-cts img {margin-bottom:20px;}
    .board-btn {text-align:center;}
    .board-btn .btn {display:inline-block; border-radius:4px; background:#343434; color:#fff; text-align:center; padding:12px 40px; line-height:1; cursor: pointer;}
    .editor-container { width: 100% !important; }
    .ck-editor__editable_inline,
    .ck-editor__editable {
        border: none !important;
        box-shadow: none !important;
        pointer-events: none !important;
    }
    .editor-container__editor {
        width: 100% !important;
        max-width: 100% !important;
        min-width: unset;
    }
    .ck-sticky-panel__content {
        border: 1px solid #fff !important;
    }
    @media screen and (max-width: 767px) {
        .ck-editor__editable_inline,
        .ck-editor__editable {
            .image { width: 100% !important; }
        }
    }
`;
export default function BoardDtl() {

    const { t } = useTranslation();
    const { pstsSqno } = useParams();
    const navigate = useNavigate();
    const [pstsInfo, setPstsInfo] = useState({});

    const location = useLocation();
    const blbdCd = location.state?.blbdCd || "BRND";

    useEffect(() => {
        if (pstsSqno) {
            callApi("GET", null, `/web/api/public/psts-detail?pstsSqno=${pstsSqno}`)
                .then(res => {
                    // console.log(res);
                    setPstsInfo(res);
                })
                .catch(err => {
                    console.error(err);
                })
        }
    }, [pstsSqno]);

    const handleEditorDataChange = (data) => {
        setPstsInfo((prevData) => ({
            ...prevData,
            pstsCn: data,
        }));
    };

    const handleBackToList = () => {
        navigate('/board', { state: { blbdCd } });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <MetaTag
                title="Photomag - Board View"
                description="Photomagで進行する様々なイベントと特別な恩恵を会ってみてください。顧客様にもっと優れた経験を提供するため、最新ニュースとプロモーション情報を迅速に伝えて上げます。"
                keywords="board, board detail, board view, notice, event"
                noIndex={true}
            />
            <Container className="container">
                <section>
                    <div className="page-header">
                        <div className="page-header-bg">
                            <img src={subHeaderBg} alt="Photomag board logo" />
                        </div>
                        <div className="inner">
                            <h2 className="page-title">{t("common.board")}</h2>
                            <p className="page-text"></p>
                        </div>
                    </div>
                </section>
                <div className="inner">
                    <BoardViewWrap>
                        <div className="board-title">{pstsInfo?.pstsTitl || ""}</div>
                        <div className="board-cts">
                            <ClassicEditor
                                name="pstsCn"
                                value={pstsInfo?.pstsCn || ""}
                                onChangeValue={handleEditorDataChange}
                                disabled={true}
                            />
                        </div>
                        <div className="board-btn">
                            <div className="btn" onClick={handleBackToList}>{t("boardPage.list")}</div>
                        </div>
                    </BoardViewWrap>
                </div>
            </Container>
        </>
    )
}