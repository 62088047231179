import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const MetaTag = ({ title, description, keywords, noIndex }) => {
    const location = useLocation();
    const currentUrl = `https://www.photomagstudio.com${location.pathname}`;
    const defaultImg = `${process.env.PUBLIC_URL}/images/photomag_thumbnail.png`;
    // JSON-LD 데이터 설정
    const jsonLdData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": title || "Photomag",
        "description": description || "雑誌のような写真で素晴らしい瞬間を",
        "url": currentUrl || "https://www.photomagstudio.com/"
    };

    // 구조화된 데이터 JSON-LD 스크립트 설정 
    useEffect(() => {
        // 기존 스크립트가 있으면 제거
        const existingScript = document.getElementById('json-ld');
        if (existingScript) {
            existingScript.remove();
        }

        // 새 스크립트 추가
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.id = 'json-ld';
        script.text = JSON.stringify(jsonLdData);
        document.head.appendChild(script);

        // 컴포넌트 언마운트 시 스크립트 제거
        return () => {
            const existingScript = document.getElementById('json-ld');
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, [jsonLdData]);

    return (
        <Helmet>
            <title>{title}</title>

            <meta name="description" content={description} />
            <meta name="keywords" content={`Photomag, magazine, photos, ${keywords}`} />
            {noIndex && <meta name="robots" content="noindex, nofollow" />}

            {/* Open Graph */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:site_name" content="Photomag" />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={defaultImg} />
            <meta property="og:url" content={currentUrl} />

            {/* SEO 중복 페이지 방지 */}
            <link rel="canonical" href={currentUrl} />
        </Helmet>
    )
};
export default MetaTag;