import styled from "styled-components";
import subHeaderBg from "../../assets/images/product/sub_header_bg.png";
import productTitleBf from "../../assets/images/product/product_title1.png";
import kioskBg from "../../assets/images/product/kiosk_bg.png";
import kioskStandard from "../../assets/images/product/kiosk_standard.png";
import kioskHighEngle from "../../assets/images/product/kiosk_angle.png";
import booth1 from "../../assets/images/product/booth_1.png";
import booth2 from "../../assets/images/product/booth_2.png";
import softwarePoint1 from "../../assets/images/product/software_point_1.png";
import softwarePoint2 from "../../assets/images/product/software_point_2.png";
import softwarePoint3 from "../../assets/images/product/software_point_3.png";
import softwareService1 from "../../assets/images/product/software_service_1_0.png";
import softwareService2 from "../../assets/images/product/software_service_2_0.png";
import softwareService3 from "../../assets/images/product/software_service_3.png";
import softwareService4 from "../../assets/images/product/software_service_4.png";
import others1 from "../../assets/images/product/others_1.png";
import others2 from "../../assets/images/product/others_2.png";
import others3 from "../../assets/images/product/others_3.png";
import others4 from "../../assets/images/product/others_4.png";

import kioskSticker1 from "../../assets/images/product/kiosk_sticker_1.png";
import kioskSticker1_1 from "../../assets/images/product/kiosk_sticker_1_1.png";
import kioskSticker1_2 from "../../assets/images/product/kiosk_sticker_1_2.png";
import kioskSticker1_3 from "../../assets/images/product/kiosk_sticker_1_3.png";
import kioskSticker1_4 from "../../assets/images/product/kiosk_sticker_1_4.png";
import kioskSticker2 from "../../assets/images/product/kiosk_sticker_2.png";
import kioskSticker2_1 from "../../assets/images/product/kiosk_sticker_2_1.png";
import kioskSticker2_2 from "../../assets/images/product/kiosk_sticker_2_2.png";
import kioskSticker2_3 from "../../assets/images/product/kiosk_sticker_2_3.png";
import kioskSticker2_4 from "../../assets/images/product/kiosk_sticker_2_4.png";
import kioskSticker3 from "../../assets/images/product/kiosk_sticker_3.png";
import kioskSticker3_1 from "../../assets/images/product/kiosk_sticker_3_1.png";
import kioskSticker3_2 from "../../assets/images/product/kiosk_sticker_3_2.png";
import kioskSticker3_3 from "../../assets/images/product/kiosk_sticker_3_3.png";
import kioskSticker3_4 from "../../assets/images/product/kiosk_sticker_3_4.png";

import { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MetaTag from "../../layout/MetaTag";

const Container = styled.div`
    .tab-pane { display: none; }
    .tab-pane.active { display: block; }
    .fade { opacity: 0; transition: opacity 0.2s ease-in-out; }
    .fade.show { opacity: 1; }
    .page-title2 {margin-top:110px;}
    .page-title3 {margin-top:110px;}
    .product-tab {
        display:flex; gap:10px; margin-bottom:60px; flex-wrap:wrap;
        .tab-btn {
            display:block; padding:10px 15px 11px; line-height:1;
            border:1px solid #5B5B5B; border-radius:8px; cursor:pointer;
            transition: transform 0.2s ease, background-color 0.2s ease, color 0.2s ease;
            &:hover {background-color:#343434; color:#fff;}
        }
        .tab-btn.active {background-color:#343434; color:#fff; font-weight:600;}
    }
    .tab-container {display:none;}
    .booth-area {display:flex; gap:20px;}
    .booth-item {flex:1 1 0;}
    .booth-thumb {
        border-radius:18px; border:1px solid rgba(255, 255, 255, 0.20); 
        overflow:hidden; 
        background:url(${kioskBg}) no-repeat center/cover;
    }
    .booth-thumb img {height:292px; margin:19px auto 10px;}
    .booth-thumb span {
        display:block; width:100%; text-align:center; color:#fff; 
        font-weight:600; background-color:#343434; padding:16px; line-height:1;
    }
    .booth-text {margin-top:20px; font-size:18px; line-height:1.5;}
    .software-point {display:flex; gap:20px;}
    .software-point-box {
            display:flex; justify-content:space-between; gap:24px; 
            flex-direction:column; flex:1 1 0; border-radius:18px; 
            border:1px solid rgba(255, 255, 255, 0.20); overflow:hidden; 
            background:url(${kioskBg}) no-repeat center/cover;
            align-items:center; padding-top:40px;
            img {max-width:80%; margin:0 auto;}
        }
    .software-point-text {
        display:block; width:100%; color:#fff; font-weight:600; 
        background-color:#343434; padding:16px 20px; text-align:left; 
        line-height:1.5; padding-left:30px; text-indent:-10px;
        span {display:block;}
    }
    .page-title3 {font-size:29px; display:block; margin-bottom:40px; font-weight:600;}
    .software-service {
        .swiper-slide {
            width:auto; text-align:center;
            span {display:block; text-align:center; font-size:18px; margin-top:18px;}
            img {height:435px;}
        }
    }
    .other-text {font-size:18px; padding-top:20px; line-height:1.5;}
    .production-box {max-width:1140px;}
    .production-box + .production-box {margin-top:60px;}
        .production-top {
        display:flex; justify-content:space-between; padding-bottom:16px;
        strong {font-size:24px; font-weight:600;}
    }
    .production-table {
        border-radius:18px; overflow:hidden; background-color:#F9FAFB; flex:auto;
        table {
            width:100%; border-collapse:collapse; font-size:18px; height:100%;
        }
        thead th {background-color:#343434; color:#fff;}
        th, td {border:1px solid #EDEDED; padding:18px 24px; text-align:center;}
        tr td:last-child {text-align:left;}
        tr:last-child th, tr:last-child td {border-bottom:0;}
        tr th:last-child, tr td:last-child {border-right:0;}
        tr th:first-child, tr td:first-child {border-left:0;}
    }

    @media screen and (max-width: 767px) {
        .title-tab .search {position: relative;margin-top:30px;}
        .store-list {grid-template-columns: repeat(1, 1fr);}
        .store-item .store-thumb {height:200px;}

        .page-title2 {font-size:24px;margin-bottom:30px;}
        .store-name {font-size:18px;}
        .store-address {display:block;font-size:15px;margin-top:6px;line-height:1.4;opacity:.7;}
        .root_daum_roughmap .wrap_map {height:250px !important;}
    }
    @media screen and (max-width: 767px) {
        .page-title2 {margin-top:80px;}
        .page-title3 {margin-top:80px;}
        .page-title3 {font-size:20px; line-height:1.3;}
        .product-tab {margin-bottom:30px; gap:8px 10px;}
        .product-tab .tab-btn {padding:8px 14px;}
        .kiosk-wrap .kiosk-table {overflow:auto;}
        .kiosk-wrap .kiosk-table table {font-size:14px; white-space:nowrap;}
        .kiosk-wrap .kiosk-table th, .production-table td {padding:12px 18px;}
        .kiosk-wrap .kiosk-desc {font-size:16px;}
        .kiosk-wrap .kiosk-thumb {width:100%; height:320px;}
        .kiosk-wrap .kiosk-thumb img {height:100%; width:100%; object-fit:cover;}
        .kiosk-wrap2 .kiosk-info {font-size:14px;}
        .kiosk-wrap2 .kiosk-info dt {white-space:nowrap;}
        .kiosk-wrap2 .kiosk-thumb {flex-direction:column; height:auto; padding:20px;}
        .kiosk-wrap2 .kiosk-thumb .thumb-main {padding:0; height:200px;}
        .kiosk-wrap2 .kiosk-thumb .thumb-sub img {height:140px;}
        .kiosk-title {font-size:20px;}
        .booth-area {flex-direction:column;}
        .booth-text {font-size:16px;}
        .booth-area {gap:30px;}
        .software-point {flex-direction:column;}
        .software-point-box {flex:1;}
        .software-service .swiper-slide span {font-size:16px;}
        .software-service .swiper-slide img {height:260px;}
        .other-text {font-size:16px;}
        .production-top strong {font-size:18px;}
        .production-top {flex-direction:column; gap:5px;}
        .production-table {overflow:auto;}
        .production-table table {font-size:14px; white-space:nowrap;}
        .production-table th, .production-table td {padding:12px 18px;}
    }
`;
const KioskTitle = styled.div`
    font-size:24px; font-weight:600; display:block; margin-bottom:20px;
    & ~ & { margin-top: 60px; }
`;
const KioskWrap = styled.div`
    display:flex; gap:20px; flex-wrap:wrap;
    .kiosk-desc {width:100%; line-height:1.5; font-size:18px;}
    .kiosk-thumb {
        position:relative; width:365px; height:365px; border-radius:18px; 
        border:1px solid rgba(255, 255, 255, 0.20); 
        background:url(${kioskBg}) no-repeat center/cover; 
        overflow:hidden;
        img { 
            position:absolute; left:50%; top:50%; 
            transform:translate(-50%, -50%);
        }
    }
    .kiosk-table {
        border-radius:18px; overflow:hidden; background-color:#F9FAFB; flex:auto;
        table {
            width:100%; border-collapse:collapse; font-size:18px; height:100%;
        }
        thead th {background-color:#343434; color:#fff;}
        th, td { border:1px solid #EDEDED; padding:18px 10px; }
        tr:last-child th, tr:last-child td { border-bottom:0; }
        tr th:last-child, tr td:last-child { border-right:0; }
        tr th:first-child, tr td:first-child { border-left:0; }
    }
`;
const KioskWrap2 = styled.div`
    .kiosk-info {
        width:100%; line-height:1.5; display:flex; gap:6px; line-height:1.75; 
        margin-top:4px; font-size:18px;
    }
    .kiosk-thumb {
        display:flex; gap:10%; align-items:center; ; width:100%; height:365px; 
        border-radius:18px; border:1px solid rgba(255, 255, 255, 0.20); 
        background:url(${kioskBg}) no-repeat center/cover; 
        overflow:hidden; margin-bottom:20px; padding-right:40px;
        .thumb-main {
            height:100%; padding-top:40px; padding-left:40px; flex:none;
            img {object-fit:cover; height:100%;}
        }
        .thumb-sub {
            flex:auto; padding:26px 0;
            .swiper-slide {width:auto;}
            img {height:224px;}
        }
    }
`;


export default function Product() {

    const { t } = useTranslation();
    const location = useLocation();
    const initialTab = location.state?.mainActiveKey || 0;

    const [mainActiveKey, setMainActiveKey] = useState(initialTab);
    const [subActiveKey, setSubActiveKey] = useState(0);

    useEffect(() => {
        setMainActiveKey(initialTab);
    }, [initialTab]);

    return (
        <>
            <MetaTag
                title="Photomag - Product"
                description="Photomagで差別化されたフォトソリューションを通じて最高の収益性と安定的な運営をお約束します。"
                keywords="Product"
            />
            <Container className="container product">
                <div className="page-header">
                    <div className="page-header-bg"><img src={productTitleBf} alt="Photomag product logo" /></div>
                    <div className="inner">
                        <h2 className="page-title">{t("common.product")}</h2>
                        <p className="page-text">{t("productPage.title")}</p>
                    </div>
                </div>
                <section>
                    <div className="inner">
                        <div className="product-tab" style={{ marginBottom: "30px" }}>
                            <button className={`tab-btn ${mainActiveKey === 0 ? 'active' : ''}`} onClick={() => setMainActiveKey(0)}>
                                {t("productPage.selfPhoto")}
                            </button>
                            <button className={`tab-btn ${mainActiveKey === 1 ? 'active' : ''}`} onClick={() => setMainActiveKey(1)}>
                                {t("productPage.photoSticker")}
                            </button>
                            {/* <button className={`tab-btn ${mainActiveKey === 2 ? 'active' : ''}`} onClick={() => setMainActiveKey(2)}>
                            신규제품
                        </button> */}
                        </div>
                        <h3 className="page-title2" style={{ marginTop: "0px" }}>{t("productPage.photoKiosk")}</h3>
                        <Tab.Container activeKey={mainActiveKey} className="tab-container">
                            <Tab.Content>
                                <Tab.Pane eventKey={0}>
                                    <KioskTitle className="kiosk-title">{t("productPage.standardKiosk")}</KioskTitle>
                                    <KioskWrap className="kiosk-wrap">
                                        <div className="kiosk-thumb"><img src={kioskStandard} alt="Photomag standard kiosk" /></div>
                                        <div className="kiosk-table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Device</th>
                                                        <th>Specification</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Monitor</th>
                                                        <td>27inch IR Touch Screen</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Camera</th>
                                                        <td>DSLR Camera Cannon Mirrorless</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Printer</th>
                                                        <td>Dye-sublimation printing</td>
                                                    </tr>
                                                    <tr>
                                                        <th>PC</th>
                                                        <td>AMD Ryzen 5 5500GT / RAM 16G / HDD 256G</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Coin Inserter</th>
                                                        <td>JY-910 / VN-3K</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p className="kiosk-desc">{t("productPage.standardKioskEx1")}
                                            <br />{t("productPage.standardKioskEx2")}</p>
                                    </KioskWrap>
                                    <KioskTitle className="kiosk-title">{t("productPage.highAngleKiosk")}</KioskTitle>
                                    <KioskWrap className="kiosk-wrap">
                                        <div className="kiosk-thumb"><img src={kioskHighEngle} alt="Photomag high angle kiosk" /></div>
                                        <div className="kiosk-table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Device</th>
                                                        <th>Specification</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Monitor</th>
                                                        <td>27inch IR Touch Screen</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Camera</th>
                                                        <td>DSLR Camera Cannon Mirrorless</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Printer</th>
                                                        <td>Dye-sublimation printing</td>
                                                    </tr>
                                                    <tr>
                                                        <th>PC</th>
                                                        <td>AMD Ryzen 5 5500GT / RAM 16G / HDD 256G</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Coin Inserter</th>
                                                        <td>JY-910 / VN-3K</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p className="kiosk-desc">{t("productPage.highAngleKioskEx1")}
                                            <br />{t("productPage.highAngleKioskEx2")}</p>
                                    </KioskWrap>
                                    <h3 className="page-title2">{t("productPage.photoBooth")}</h3>
                                    <div className="booth-area">
                                        <div className="booth-item">
                                            <div className="booth-thumb">
                                                <img src={booth1} alt="Photomag booth1" />
                                                <span>{t("productPage.standardBooth")}</span>
                                            </div>
                                            <p className="booth-text">{t("productPage.standardBoothEx1")}
                                                <br />{t("productPage.standardBoothEx2")}</p>
                                        </div>
                                        <div className="booth-item">
                                            <div className="booth-thumb">
                                                <img src={booth2} alt="Photomag booth2" />
                                                <span>{t("productPage.elevatorBooth")}</span>
                                            </div>
                                            <p className="booth-text">{t("productPage.elevatorBoothEx1")}
                                                <br />{t("productPage.elevatorBoothEx2")}</p>
                                        </div>
                                    </div>
                                    <h3 className="page-title2">{t("productPage.photoSoftware")}</h3>
                                    <div className="software-point">
                                        <div className="software-point-box">
                                            <div className="software-point-thumb">
                                                <img src={softwarePoint1} alt="Photomag softwarePoint1" />
                                            </div>
                                            <p className="software-point-text"><span>- {t("productPage.photoSoftwareEx1")}</span><span>- {t("productPage.photoSoftwareEx4")}</span></p>
                                        </div>
                                        <div className="software-point-box">
                                            <div className="software-point-thumb">
                                                <img src={softwarePoint2} alt="Photomag softwarePoint2" />
                                            </div>
                                            <p className="software-point-text"><span>- {t("productPage.photoSoftwareEx5")}</span><span>- {t("productPage.photoSoftwareEx6")}</span></p>
                                        </div>
                                        <div className="software-point-box">
                                            <div className="software-point-thumb">
                                                <img src={softwarePoint3} alt="Photomag softwarePoint3" />
                                            </div>
                                            <p className="software-point-text"><span>- {t("productPage.photoSoftwareEx7")}</span><span>- {t("productPage.photoSoftwareEx8")}</span></p>
                                        </div>
                                    </div>
                                    <h4 className="page-title3" style={{ fontSize: "" }}>{t("productPage.photoSoftwareEx2")} {t("productPage.photoSoftwareEx3")}</h4>
                                    <Swiper
                                        slidesPerView={"auto"}
                                        spaceBetween={20}
                                        speed={400}
                                        className="software-service"
                                    >
                                        <SwiperSlide>
                                            <img src={softwareService1} alt="Photomag softwareService1" />
                                            <span>{t("productPage.warmFilter")}</span>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src={softwareService2} alt="Photomag softwareService2" />
                                            <span>{t("productPage.coolFilter")}</span>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src={softwareService3} alt="Photomag softwareService3" />
                                            <span>{t("productPage.whiteningFilter")}</span>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src={softwareService4} alt="Photomag softwareService4" style={{ borderRadius: "8px" }} />
                                            <span>{t("productPage.callaboFrame")}</span>
                                        </SwiperSlide>
                                    </Swiper>
                                    <h4 className="page-title3">Others</h4>
                                    <div className="product-tab">
                                        <button className={`tab-btn ${subActiveKey === 0 ? 'active' : ''}`} onClick={() => setSubActiveKey(0)}>
                                            {t("productPage.salesMgt")}
                                        </button>
                                        <button className={`tab-btn ${subActiveKey === 1 ? 'active' : ''}`} onClick={() => setSubActiveKey(1)}>
                                            {t("productPage.dvcMgt")}
                                        </button>
                                        <button className={`tab-btn ${subActiveKey === 2 ? 'active' : ''}`} onClick={() => setSubActiveKey(2)}>
                                            {t("productPage.paperMgt")}
                                        </button>
                                        <button className={`tab-btn ${subActiveKey === 3 ? 'active' : ''}`} onClick={() => setSubActiveKey(3)}>
                                            {t("productPage.manyLng")}
                                        </button>
                                    </div>
                                    <Tab.Container activeKey={subActiveKey}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey={0}>
                                                <img src={others1} alt="Photomag others1" />
                                                <p className="other-text">{t("productPage.salesMgtEx")}</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={1}>
                                                <img src={others2} alt="Photomag others2" />
                                                <p className="other-text">{t("productPage.dvcMgtEx")}</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={2}>
                                                <img src={others3} alt="Photomag others3" />
                                                <p className="other-text">{t("productPage.paperMgtEx")}</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={3}>
                                                <img src={others4} alt="Photomag others4" />
                                                <p className="other-text">{t("productPage.manyLngEx")}</p>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey={1}>
                                    <KioskTitle className="kiosk-title">{t("productPage.colorStKiosk")}</KioskTitle>
                                    <KioskWrap2 className="kiosk-wrap2">
                                        <div className="kiosk-thumb">
                                            <div className="thumb-main"><img src={kioskSticker1} alt="Photomag kioskSticker1" /></div>
                                            <Swiper
                                                slidesPerView={"auto"}
                                                spaceBetween={40}
                                                speed={400}
                                                scrollbar={{ hide: false, draggable: true }}
                                                modules={[Scrollbar]}
                                                className="thumb-sub"
                                            >
                                                <SwiperSlide><img src={kioskSticker1_1} alt="Photomag kioskSticker1_1" /></SwiperSlide>
                                                <SwiperSlide><img src={kioskSticker1_2} alt="Photomag kioskSticker1_2" /></SwiperSlide>
                                                <SwiperSlide><img src={kioskSticker1_3} alt="Photomag kioskSticker1_3" /></SwiperSlide>
                                                <SwiperSlide><img src={kioskSticker1_4} alt="Photomag kioskSticker1_4" /></SwiperSlide>
                                            </Swiper>
                                        </div>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.colorSize")} :</dt>
                                            <dd>{t("productPage.colorStKioskSize")}</dd>
                                        </dl>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.characteristic")} :</dt>
                                            <dd>
                                                <ul>
                                                    <li>(1) {t("productPage.colorNmStKioskEx1")}</li>
                                                    <li>(2) {t("productPage.colorNmStKioskEx2")}</li>
                                                    <li>(3) {t("productPage.colorNmStKioskEx3")}</li>
                                                    <li>(4) {t("productPage.colorNmStKioskEx4")}</li>
                                                    <li>(5) {t("productPage.colorNmStKioskEx5")}</li>
                                                </ul>
                                            </dd>
                                        </dl>
                                    </KioskWrap2>
                                    <KioskTitle className="kiosk-title">{t("productPage.polapolaSt")}</KioskTitle>
                                    <KioskWrap2 className="kiosk-wrap2">
                                        <div className="kiosk-thumb">
                                            <div className="thumb-main"><img src={kioskSticker2} alt="Photomag kioskSticker2" /></div>
                                            <Swiper
                                                slidesPerView={"auto"}
                                                spaceBetween={40}
                                                speed={400}
                                                scrollbar={{ hide: false, draggable: true }}
                                                modules={[Scrollbar]}
                                                className="thumb-sub"
                                            >
                                                <SwiperSlide><img src={kioskSticker2_1} alt="Photomag kioskSticker2_1" /></SwiperSlide>
                                                <SwiperSlide><img src={kioskSticker2_2} alt="Photomag kioskSticker2_2" /></SwiperSlide>
                                                <SwiperSlide><img src={kioskSticker2_3} alt="Photomag kioskSticker2_3" /></SwiperSlide>
                                                <SwiperSlide><img src={kioskSticker2_4} alt="Photomag kioskSticker2_4" /></SwiperSlide>
                                            </Swiper>
                                        </div>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.colorSize")} :</dt>
                                            <dd>{t("productPage.colorStKioskSize")}</dd>
                                        </dl>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.characteristic")} :</dt>
                                            <dd>
                                                <ul>
                                                    <li>(1) {t("productPage.polapolaEx1")}</li>
                                                    <li>(2) {t("productPage.polapolaEx2")}</li>
                                                    <li>(3) {t("productPage.polapolaEx3")} - {t("productPage.polapolaEx3-1")}</li>
                                                    <li>(4) {t("productPage.polapolaEx4")}</li>
                                                </ul>
                                            </dd>
                                        </dl>
                                    </KioskWrap2>
                                    <KioskTitle className="kiosk-title">{t("productPage.poluv")}</KioskTitle>
                                    <KioskWrap2 className="kiosk-wrap2">
                                        <div className="kiosk-thumb">
                                            <div className="thumb-main"><img src={kioskSticker3} alt="Photomag kioskSticker3" /></div>
                                            <Swiper
                                                slidesPerView={"auto"}
                                                spaceBetween={40}
                                                speed={400}
                                                scrollbar={{ hide: false, draggable: true }}
                                                modules={[Scrollbar]}
                                                className="thumb-sub"
                                            >
                                                <SwiperSlide><img src={kioskSticker3_1} alt="Photomag kioskSticker3_1" /></SwiperSlide>
                                                <SwiperSlide><img src={kioskSticker3_2} alt="Photomag kioskSticker3_2" /></SwiperSlide>
                                                <SwiperSlide><img src={kioskSticker3_3} alt="Photomag kioskSticker3_3" /></SwiperSlide>
                                                <SwiperSlide><img src={kioskSticker3_4} alt="Photomag kioskSticker3_4" /></SwiperSlide>
                                            </Swiper>
                                        </div>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.colorSize")} :</dt>
                                            <dd>{t("productPage.colorStKioskSize")}</dd>
                                        </dl>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.characteristic")} :</dt>
                                            <dd>
                                                <ul>
                                                    <li>(1) {t("productPage.poluvEx1")}</li>
                                                    <li>(2) {t("productPage.poluvEx2")}</li>
                                                    <li>(3) {t("productPage.poluvEx3")}</li>
                                                    <li>(4) {t("productPage.poluvEx4")}</li>
                                                    <li>(5) {t("productPage.poluvEx5")}</li>
                                                    <li>(6) {t("productPage.poluvEx6")}</li>
                                                </ul>
                                            </dd>
                                        </dl>
                                    </KioskWrap2>
                                </Tab.Pane>
                                <Tab.Pane eventKey={2}>

                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section>
                {/* <section>
                <div className="inner">
                    <h4 className="page-title3">제작 가격</h4>
                    <div className="production-box">
                        <div className="production-top">
                            <strong>하드웨어 제작 판매 가격</strong>
                            <span>(단위:원/부가세포함)</span>
                        </div>
                        <div className="production-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>제품명</th>
                                        <th>판매지역</th>
                                        <th>판매가</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowSpan={2}>Standard kiosk</td>
                                        <td>국내</td>
                                        <td>10,000,000</td>
                                        <td>100대 이상 계약 시 대당 50만원 할인</td>
                                    </tr>
                                    <tr>
                                        <td>해외</td>
                                        <td>10,000,000 (면세)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>High angle kiosk</td>
                                        <td>국내</td>
                                        <td>10,000,000</td>
                                        <td>100대 이상 계약 시 대당 50만원 할인</td>
                                    </tr>
                                    <tr>
                                        <td>해외</td>
                                        <td>10,000,000 (면세)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>Standard booth</td>
                                        <td>국내</td>
                                        <td>10,000,000</td>
                                        <td>100대 이상 계약 시 대당 50만원 할인</td>
                                    </tr>
                                    <tr>
                                        <td>해외</td>
                                        <td>10,000,000 (면세)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>Elevator booth</td>
                                        <td>국내</td>
                                        <td>10,000,000</td>
                                        <td>100대 이상 계약 시 대당 50만원 할인</td>
                                    </tr>
                                    <tr>
                                        <td>해외</td>
                                        <td>10,000,000 (면세)</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="production-box">
                        <div className="production-top">
                            <strong>소프트웨어 제작 판매 가격</strong>
                            <span>(단위:원/부가세포함)</span>
                        </div>
                        <div className="production-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>제품명</th>
                                        <th>판매지역</th>
                                        <th>판매가</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowSpan={2}>Photomag</td>
                                        <td>국내</td>
                                        <td>10,000,000</td>
                                        <td>100대 이상 계약 시 대당 50만원 할인</td>
                                    </tr>
                                    <tr>
                                        <td>해외</td>
                                        <td>10,000,000 (면세)</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section> */}
            </Container>
        </>
    )
}