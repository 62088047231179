import styled from "styled-components";
import spaceBox1 from "../../assets/images/home/special_box_1.png";
import spaceBox2 from "../../assets/images/home/special_box_2.png";
import spaceBox3 from "../../assets/images/home/special_box_3.png";
import spaceBox4 from "../../assets/images/home/special_box_4.png";
import spaceBox5 from "../../assets/images/home/special_box_5.png";
import spaceBox6 from "../../assets/images/home/special_box_6.png";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Scrollbar } from 'swiper/modules';
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SpecialWrap = styled.section`
    position: relative;
    overflow: hidden; /* 오른쪽으로 넘침 방지 */
    .title-area { margin-bottom: 60px; }
    .title { font-size: 36px; font-weight: 600; margin-bottom: 10px; }
    .subtitle { font-size: 26px; font-weight: 400; }
    .special-slide { 
        overflow: hidden;
        position: relative;
    }
    .special-box {
        position: relative;
        height: 365px;
        overflow: hidden;
        border-radius: 18px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        color: #fff;

        strong { font-size: 26px; font-weight: 600; display: block; }
        p { margin-top: 20px; line-height: 1.4; }
        // p태그에 word-break: keep-all; 지웠음.
    }
    .special-box-bg { width: 100%; height: 100%; object-fit: cover; }
    .special-box-inner {
        background: rgba(0, 0, 0, 0.20);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        backdrop-filter: blur(6px);
        padding: 24px 20px;
    }
    .special-slide-prev, .special-slide-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        box-shadow: 1px 1px 5.9px 0px rgba(0, 0, 0, 0.06);
        width: 52px;
        height: 52px;
        border-radius: 100px;
        background-color: #fff;
        z-index: 10;
        cursor: pointer;

        &:hover {
            background-color: #343434;
            color: #fff;
            transition: 0.3s all;
        }
    }
    .special-slide-prev {
        left: -26px;
    }
    .special-slide-next {
        right: -26px;
    }
    .special-slide-scrollbar {
        position: relative;
        width: 100%;
        height: 3px;
        background-color: rgba(0, 0, 0, 0.1);
        margin-top: 10px;
        border-radius: 5px;
    }
`;

export default function Special({ prevRef, nextRef }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        if (prevRef.current && nextRef.current) {
            const swiperInstance = document.querySelector('.special-slide').swiper;
            swiperInstance.params.navigation.prevEl = prevRef.current;
            swiperInstance.params.navigation.nextEl = nextRef.current;
            swiperInstance.navigation.init();
            swiperInstance.navigation.update();
        }
    }, [prevRef, nextRef]);

    const slidesData = [
        { image: spaceBox1, title: t("mainPage.photoKiosk"), subtitle: t("mainPage.speCialSub1"), spcUrl: "/product" },
        { image: spaceBox2, title: t("mainPage.photoBooth"), subtitle: t("mainPage.speCialSub2"), spcUrl: "/product" },
        { image: spaceBox3, title: t("mainPage.photoSoftware"), subtitle: t("mainPage.speCialSub3"), spcUrl: "/product" },
        { image: spaceBox4, title: t("mainPage.colorSt"), subtitle: t("mainPage.speCialSub4"), spcUrl: "/product" },
        { image: spaceBox5, title: t("mainPage.polapolaSt"), subtitle: t("mainPage.speCialSub5"), spcUrl: "/product" },
        { image: spaceBox6, title: t("mainPage.poluv"), subtitle: t("mainPage.speCialSub6"), spcUrl: "/product" },
    ];

    return (
        <SpecialWrap className="special">
            <div className="inner">
                <div className="title-area">
                    <h2 className="title">{t("mainPage.specialTitle1")}</h2>
                    <h2 className="subtitle">{t("mainPage.specialTitle2")}</h2>
                </div>
                <div style={{ width: "100%", position: "relative" }}>
                    <Swiper
                        className="special-slide"
                        slidesPerView={1}
                        spaceBetween={20}
                        speed={500}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                        }}
                        scrollbar={{
                            el: '.special-slide-scrollbar',
                            hide: false,
                            draggable: true,
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1440: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}
                        modules={[Navigation, Scrollbar]}
                    >
                        <div className="swiper-wrapper">
                            {slidesData.map((slide, idx) => (
                                <SwiperSlide key={idx} style={{cursor: "pointer"}} 
                                onClick={() => {
                                    const tabKey = idx < 3 ? 0 : 1;
                                    navigate(slide.spcUrl, { state: { mainActiveKey: tabKey }});
                                    window.scrollTo(0, 0); 
                                }}>
                                    <div className="special-box">
                                        <img className="special-box-bg" src={slide.image} alt="Photomag special slide" />
                                        <div className="special-box-inner">
                                            <strong>{slide.title}</strong>
                                            <p>{slide.subtitle}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </div>
                    </Swiper>
                    <div className="special-slide-scrollbar"></div>
                    <div ref={prevRef} className="special-slide-prev">
                        <span className="material-icons-round">chevron_left</span>
                    </div>
                    <div ref={nextRef} className="special-slide-next">
                        <span className="material-icons-round">chevron_right</span>
                    </div>
                </div>
            </div>
        </SpecialWrap>
    )
}