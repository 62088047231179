import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

function CustomBreadcrumbs() {

    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);

    // console.log(pathnames);
    

    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">Home</Link>
            {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                if (index === pathnames.length - 1) {
                    return <Typography color="textPrimary" key={to}>{value.charAt(0).toUpperCase() + value.slice(1)}</Typography>;
                } else {
                    return (
                        <Link to={to} key={to}>
                            {value.charAt(0).toUpperCase() + value.slice(1)}
                        </Link>
                    );
                }
            })}
        </Breadcrumbs>
    );

}
export default CustomBreadcrumbs;