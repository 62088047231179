import styled from "styled-components";
import storeThumb1 from "../../assets/images/home/store_thumb_1.png";
import storeThumb2 from "../../assets/images/home/store_thumb_2.png";
import storeThumb3 from "../../assets/images/home/store_thumb_3.png";
import storeThumb4 from "../../assets/images/home/store_thumb_4.png";
import storeThumb5 from "../../assets/images/home/store_thumb_5.png";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { useEffect, useState } from "react";
import { callApi } from "../../util/HttpService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const StoreWrap = styled.section`
    .title-area {margin-bottom:60px;}
    .title {font-size:36px; font-weight:600; margin-bottom:10px;}
    .subtitle {font-size:26px; font-weight:400;}

    .store-slide-prev {
        display:flex; 
        align-items:center; 
        justify-content:center; 
        border:1px solid #333D4B; 
        font-size:24px; 
        box-shadow:1px 1px 5.9px 0px rgba(0, 0, 0, 0.06); 
        width:52px; 
        height:52px;
        border-radius:100px; 
        background-color:#fff; 
        z-index:1;
        cursor: pointer;
        &:hover {
            background-color:#343434; 
            color:#fff;
            transition: 0.3s all;
        }
    }
    .store-slide-next {
        display:flex; 
        align-items:center; 
        justify-content:center; 
        border:1px solid #333D4B; 
        font-size:24px; 
        box-shadow:1px 1px 5.9px 0px rgba(0, 0, 0, 0.06); 
        width:52px; 
        height:52px;
        border-radius:100px; 
        background-color:#fff; 
        z-index:1;
        cursor: pointer;
        &:hover {
            background-color:#343434; 
            color:#fff;
            transition: 0.3s all;
        }
    }
    .store-slide {
        padding:0 20px;
        .store-slide-navigation {
            display:flex; 
            gap:20px; 
            justify-content:end; 
            margin-top:42px;
        }
        .swiper-slide {
            width:auto; 
            text-align:center;
            img { width: 408px; height: 567px; }
            @media screen and (max-width: 767px) {
                img { width: 100%; height: 311px; }
            }
            .store-thumb { margin-bottom:20px; }
            .store-title { display:block; font-weight:600; font-size:18px; margin-bottom:7px; }
            .store-address { display:block; color:#A7A7A7; }
        }
    }
`;

export default function Store({ prevRef, nextRef }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [streList, setStreList] = useState([]);

    useEffect(() => {
        if (prevRef.current && nextRef.current) {
            const swiperInstance = document.querySelector('.store-slide').swiper;
            swiperInstance.params.navigation.prevEl = prevRef.current;
            swiperInstance.params.navigation.nextEl = nextRef.current;
            swiperInstance.navigation.init();
            swiperInstance.navigation.update();
        }
    }, []);

    useEffect(() => {
        callApi("GET", null, `/web/api/public/main-list?blbdCd=STGD`)
            .then(res => {
                // console.log(res.content); // 응답 확인
                if (res) {
                    setStreList(res);
                }
            })
            .catch(err => {
                console.error(err);
                setStreList([]); // 에러 발생 시 빈 배열 설정
            });
    }, []);


    return (
        <StoreWrap className="store">
            <div className="inner">
                <div className="title-area">
                    <h2 className="title">{t("common.store")}</h2>
                </div>
            </div>
            <Swiper
                className="store-slide"
                slidesPerView={"auto"}
                spaceBetween={20}
                speed={500}
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                }}
                modules={[Navigation]}
            >
                <div className="swiper-wrapper">
                    {streList.map((data, idx) => (
                        <SwiperSlide key={data.pstsSqno} onClick={() => navigate(`/store/store-view/${data.pstsSqno}`)} style={{cursor: "pointer"}}>
                            <img className="store-thumb" src={`https://photomag-board-upload-file.s3.amazonaws.com/${data.mainStreImgPathAddr}/${data.mainStreImgFileNm}`} alt="Photomag store" />
                            <strong className="store-title">{data.pstsTitl}</strong>
                            <span className="store-address">{data.streAddr}</span>
                        </SwiperSlide>
                    ))}
                </div>
                <div className="inner">
                    <div className="store-slide-navigation">
                        <div ref={prevRef} className="store-slide-prev">
                            <span className="material-icons-round">chevron_left</span>
                        </div>
                        <div ref={nextRef} className="store-slide-next">
                            <span className="material-icons-round">chevron_right</span>
                        </div>
                    </div>
                </div>
            </Swiper>
        </StoreWrap>
    )
}