import styled from "styled-components";
import subHeaderBg from "../../assets/images/contact/sub_header_bg.png";

import CmmnRadio from "../../cmmn/CmmnRadio";
import { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import CmmnForm from "../../cmmn/CmmnForm";
import { callApi } from "../../util/HttpService";
import * as yup from 'yup';
import Partner from "./partner/Partner";
import Abroad from "./abroad/Abroad";
import Startup from "./startup/Startup";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatContentWithParagraphs } from "../../util/CmmnUtils";
import MetaTag from "../../layout/MetaTag";

const Container = styled.div`
    .tab-pane { display: none; }
    .tab-pane.active { display: block; }
    .fade { opacity: 0; transition: opacity 0.2s ease-in-out; }
    .fade.show { opacity: 1; }
    .contact-kind label { padding: 12px 15px 11px; }
    @media screen and (max-width: 767px) {
        .page-text { word-break: unset !important; }
    }
`;
const ContactKind = styled.div`
    display:flex; gap:10px; margin-bottom:60px; flex-wrap:wrap; 
    justify-content:center;
    @media screen and (max-width: 767px) {
        label {
            padding: ${props => props.$language === "jp" ? "10px 9px 10px !important" : "10px 12px 10px !important"};
            font-size: ${props => props.$language === "jp" ? "14px !important" : "15px !important"}; 
        }
    }
    a {
        display:block; padding:12px 20px 11px; line-height:1; 
        border:1px solid #5B5B5B; border-radius:8px; cursor:pointer; color:#333D4B;
    }
    .active {
        background-color:#343434; color:#fff; font-weight:600;
    }
`;
const ContactForm = styled.div`
    margin:0 auto; max-width:565px;
    .row {
        display:flex; margin-bottom:34px; gap:20px;
    }
    .col {
        flex:1 1 0;
        &:first-child { flex:1 1 40%; }
        &:last-child { flex:1 1 60%; }
    }
    .input-label {
        display:block; font-weight:600; margin-bottom:10px; font-size:20px;
    }
    .btn {
        width:100%; text-align:center; color:#fff; background-color:#343434; 
        border-radius:8px; height:50px; line-height:1;
    }
    textarea {
        border-radius:8px; padding:20px; resize:none; outline:0; border:0; 
        width:100%; background:#F9FAFB;
    }
    .input {
        width:100%; height:50px; padding:0 16px; outline:0; border:0; 
        border-radius:8px; background:#F2F2F2;
        &::placeholder { color:#C6C6C6; }
        &::-webkit-input-placeholder { color:#C6C6C6; }
        &:-moz-placeholder { color:#C6C6C6; }
        &::-moz-placeholder { color:#C6C6C6; }
        &:-ms-input-placeholder { color:#C6C6C6; }
    }
    .upload-set {
        position:relative; display:flex; gap:10px;
        input.upload-text {
            width:calc(100% - 110px); height:50px; flex:auto; border-radius:8px; 
            outline:0; padding:0 16px; border:1px solid #A0A0A0;
        }
        div.upload-btn-wrap {
            overflow:hidden; position:relative; width:110px; height:50px;
            input.input-file {
                position:absolute; top:0; right:0; cursor:pointer; opacity:0; 
                filter:alpha(opacity=0); -ms-filter:"alpha(opacity=0)"; 
                -moz-opacity:0;
            }
            label {
                display: inline-block; width:100%; height:100%; background:#F9FAFB;
                border:1px solid #A0A0A0; border-radius:8px; cursor: pointer;
                span {
                    display: flex; justify-content: center; align-items: center;
                    height: 100%;
                }
                &:hover {
                    background-color: #E5E7EB; border-color: #6B7280;
                    transition: 0.2s all;
                }
            }
        }
    }
    .agree {
        margin-bottom:36px;
        .check {
            position:relative; display:inline-flex; text-align:left; 
            vertical-align:middle; cursor:pointer;
            input {
                position:absolute; left:0; top:0; height:100%; border:none; 
                background:transparent; z-index:2;
                &:checked + label i, :checked + label svg {
                    border-color:#343434; background-color:#343434;
                }
                &:checked + label .material-icons-round {
                    opacity:1;
                }
            }
            label {
                display:inline-flex; word-break:break-word; align-items:center; 
                gap:10px; cursor:pointer;
                i, svg {
                    display:flex; align-items:center; justify-content:center; 
                    width:24px; height:24px; border:1px solid #B7BBBF; 
                    border-radius:4px; box-sizing:border-box; flex:none; 
                    background-color:#fff; transition:all .3s ease;
                }
                .material-icons-round {
                    opacity:0; color:#fff; transition:opacity .3s ease; 
                    font-size:1.1em; display:block;
                }
            }
        }
    }
`;

export default function Inquiry() {

    const { t, i18n } = useTranslation();
    const validationSchema = yup.object().shape({
        iqryTypeCd: yup.string().required(),
        coNm: yup.string().test(
            'coNmTest',
            `${t("common.coNm")}`,
            function (value) {
                const { iqryTypeCd } = this.parent;
                if (iqryTypeCd !== 'FUIQ' && !value) {
                    return false;
                }
                return true;
            }
        ),
        iqrrNm: yup.string().test(
            'iqrrNmTest',
            function (value) {
                const { iqryTypeCd } = this.parent;
                if (!value) {
                    return this.createError({
                        message: iqryTypeCd === 'FUIQ' ? `${t("common.name")}` : `${t("common.manager")}`,
                    });
                }
                return true;
            }
        ),
        founZoneNm: yup.string().test(
            'founZoneNmTest',
            `${t("common.fz")}`,
            function (value) {
                const { iqryTypeCd } = this.parent;
                if (iqryTypeCd === 'FUIQ' && !value) {
                    return false;
                }
                return true;
            }
        ),
        iqrrTelNo: yup.string().required(t("common.tel")),
        iqrrEmalAddr: yup.string().required(t("common.email")),
    });

    const partMenu = [
        { label: t("common.partner"), value: "AFIQ" },
        { label: t("common.startup"), value: "FUIQ" },
        { label: t("common.abroad"), value: "GFIQ" },
    ];

    const defaultIqryInfo = {
        iqryTypeCd: "",
        coNm: "",
        iqrrNm: "",
        iqrrTelNo: "",
        iqrrEmalAddr: "",
        iqryCn: "",
        founZoneNm: "",
    };
    const { type } = useParams();
    const navigate = useNavigate();
    const [iqryInfo, setIqryInfo] = useState(defaultIqryInfo);
    const [errors, setErrors] = useState({});
    const [afiqFile, setAfiqFile] = useState(null);
    const [gfiqFile, setGfiqFile] = useState(null);

    useEffect(() => {
        setAfiqFile(null);
        setGfiqFile(null);
        if (type === 'partner') {
            setIqryInfo({ ...defaultIqryInfo, iqryTypeCd: 'AFIQ' });
        } else if (type === 'startup') {
            setIqryInfo({ ...defaultIqryInfo, iqryTypeCd: 'FUIQ' });
        } else if (type === 'abroad') {
            setIqryInfo({ ...defaultIqryInfo, iqryTypeCd: 'GFIQ' });
        }
    }, [type]);

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        const fomattedValue = name === "iqrrTelNo" ? value.replace(/[^0-9]/g, "") : value;
        setIqryInfo((prevData) => ({
            ...prevData,
            [name]: fomattedValue,
        }));
        if (name === "iqryTypeCd") {
            // setAgreeStatus({ AFIQ: false, FUIQ: false, GFIQ: false });
            setIqryInfo({ ...defaultIqryInfo, iqryTypeCd: fomattedValue });
            setErrors({});
            setAfiqFile(null);
            setGfiqFile(null);
            if (value === 'AFIQ') {
                navigate('/inquiry/partner');
            } else if (value === 'FUIQ') {
                navigate('/inquiry/startup');
            } else if (value === 'GFIQ') {
                navigate('/inquiry/abroad');
            }
        }
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (fomattedValue !== '') {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleAfiqFileSelect = (selectedFile) => {
        setAfiqFile(selectedFile);
    };
    const handleGfiqFileSelect = (selectedFile) => {
        setGfiqFile(selectedFile);
    };

    const iqryInfoSave = async () => {
        try {

            await validationSchema.validate(iqryInfo, {
                abortEarly: false,
                context: { iqryTypeCd: iqryInfo.iqryTypeCd }
            });

            const formattedIqryCn = formatContentWithParagraphs(iqryInfo.iqryCn);

            const formData = new FormData();
            const fileToUpload = iqryInfo.iqryTypeCd === 'AFIQ' ? afiqFile : gfiqFile;

            formData.append('file', fileToUpload);
            formData.append('dirName', 'image');
            formData.append('iqryInfo', new Blob([JSON.stringify({ ...iqryInfo, iqryCn: formattedIqryCn })], { type: 'application/json' }));

            await callApi("POST", formData, "/web/api/public/save", {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(res => {
                    console.log(res);
                    alert(t("alert.iqrySuccess"));
                    setIqryInfo((prevState) => ({
                        ...defaultIqryInfo,
                        iqryTypeCd: prevState.iqryTypeCd,
                    }));
                    setAfiqFile(null);
                    setGfiqFile(null);
                })
                .catch(err => {
                    console.log(err);
                    alert(t("alert.iqryFail"));
                });
        } catch (err) {
            const newErrors = {};
            if (err.inner && err.inner.length > 0) {
                err.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
            } else {
                newErrors[err.path] = err.message;
            }
            setErrors(newErrors);
        }
    };

    // useEffect(() => {
    //     console.log(iqryInfo);
    // }, [iqryInfo]);

    return (
        <>
            <MetaTag
                title={`Photomag - ${
                    iqryInfo && iqryInfo.iqryTypeCd === "AFIQ" 
                    ? "Partner" 
                    : iqryInfo.iqryTypeCd === "FUIQ" 
                    ? "Startup" 
                    : iqryInfo.iqryTypeCd === "GFIQ"
                    ? "Aboard"
                    : "Inquiry" 
                }`}
                description={`${
                    iqryInfo && iqryInfo.iqryTypeCd === "AFIQ" 
                    ? "Photomagで様々な分野のイベント/公演会場でのご参加をお待ちしております。"
                    : iqryInfo.iqryTypeCd === "FUIQ" 
                    ? "Photomagで加盟店の持続的な収益創出、安定的な運営のために努力します。"
                    : iqryInfo.iqryTypeCd === "GFIQ" 
                    ? "Photomagでグローバル市場進出を希望するすべての方々と共にします。"
                    : "Photomagで様々な分野のイベント/公演会場でのご参加をお待ちしております。"
                }`}
                keywords={`${
                    iqryInfo && iqryInfo.iqryTypeCd === "AFIQ" 
                    ? "inquiry, partner, 提携クエリ"
                    : iqryInfo.iqryTypeCd === "FUIQ" 
                    ? "inquiry, startup, 創業クエリ"
                    : iqryInfo.iqryTypeCd === "GFIQ" 
                    ? "inquiry, abroad, 海外創業クエリ"
                    : "inquiry"
                }`}
            />
            <Container className="container">
                <section>
                    <div className="page-header">
                        <div className="page-header-bg">
                            <img src={subHeaderBg} alt="Photomag Inquiry logo" />
                        </div>
                        <div className="inner">
                            <h2 className="page-title">{t("mainPage.inquriyTitle")}</h2>
                            <p className="page-text">
                                {iqryInfo.iqryTypeCd === "AFIQ" && t("partnerPage.partnerEx")}
                                {iqryInfo.iqryTypeCd === "FUIQ" && t("partnerPage.detailEx")}
                                {iqryInfo.iqryTypeCd === "GFIQ" && t("startupPage.stpCstEx")}
                            </p>
                        </div>
                    </div>
                    <div className="inner">
                        <ContactKind className="contact-kind" $language={i18n.language}>
                            <CmmnRadio
                                dataArray={partMenu}
                                onSelectChange={handleFormDataChange}
                                defaultVal={iqryInfo.iqryTypeCd}
                                tagName="iqryTypeCd"
                            />
                        </ContactKind>
                        <Tab.Container activeKey={iqryInfo.iqryTypeCd}>
                            <Tab.Content>
                                <Tab.Pane eventKey="AFIQ">
                                    <Partner />
                                    <ContactForm className="contact-form">
                                        <CmmnForm
                                            id={`afiq_${iqryInfo.iqryTypeCd}`}
                                            formData={iqryInfo}
                                            onFormDataChange={handleFormDataChange}
                                            // onCheckboxChange={(e) => handleCheckboxChange(e, activeKey)}
                                            // checked={agreeStatus[activeKey]}
                                            onFileInfoUpdate={handleAfiqFileSelect}
                                            fileName={afiqFile?.name || ""}
                                            onSubmit={iqryInfoSave}
                                            errors={errors}
                                        />
                                    </ContactForm>
                                </Tab.Pane>
                                <Tab.Pane eventKey="FUIQ">
                                    <Startup />
                                    <ContactForm className="contact-form">
                                        <CmmnForm
                                            formData={iqryInfo}
                                            onFormDataChange={handleFormDataChange}
                                            // onCheckboxChange={(e) => handleCheckboxChange(e, activeKey)}
                                            // checked={agreeStatus[activeKey]}
                                            onSubmit={iqryInfoSave}
                                            errors={errors}
                                        />
                                    </ContactForm>
                                </Tab.Pane>
                                <Tab.Pane eventKey="GFIQ">
                                    <Abroad />
                                    <ContactForm className="contact-form">
                                        <CmmnForm
                                            id={`gfiq_${iqryInfo.iqryTypeCd}`}
                                            formData={iqryInfo}
                                            onFormDataChange={handleFormDataChange}
                                            // onCheckboxChange={(e) => handleCheckboxChange(e, activeKey)}
                                            // checked={agreeStatus[activeKey]}
                                            onFileInfoUpdate={handleGfiqFileSelect}
                                            fileName={gfiqFile?.name || ""}
                                            onSubmit={iqryInfoSave}
                                            errors={errors}
                                        />
                                    </ContactForm>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section>
            </Container>
        </>
    )
}