import styled from "styled-components";
import partnerStep1 from "../../../assets/images/contact/partner_step_1.png";
import partnerStep2 from "../../../assets/images/contact/partner_step_2.png";
import partnerStep3 from "../../../assets/images/contact/partner_step_3.png";
import partnerStep4 from "../../../assets/images/contact/partner_step_4.png";
import { useTranslation } from "react-i18next";

const ContactStep = styled.div`
    .cs-jp {
        font-size: ${props => props.$jp ? "1.5rem !important" : ""};
    }
    @media screen and (max-width: 767px) {
        .cs-jp { 
            font-size: ${props => props.$jp ? "13px !important" : "14px !important"}; 
        }
    }
    grid-template-columns:repeat(4, 1fr); display:grid; max-width:760px; 
    margin:0 auto 90px; gap:40px 0;
    .step-box { 
        text-align:center; 
        & > strong {
            display:block; font-size:20px; font-weight:600; margin-bottom:10px;
        }
        & > span {
            line-height:1.3;
        }
        .step-icon {
            position:relative; width:132px; height:132px; border-radius:50%; 
            background-color:#F9FAFB; display:flex; align-items:center; 
            justify-content:center; margin:0 auto 20px;
            &:after {
                content:''; position:absolute; display:block; top:50%; 
                transform:translateY(-50%); right:-100%; width:100%; height:1px; 
                background-color:#EDEDED;
            }
        }
        &:last-child .step-icon:after {
            content:none;
        }
        &:nth-child(4n) .step-icon:after {
            content:none;
        }
    }
`;

export default function Partner() {
    const { t, i18n } = useTranslation();
    const jp = i18n.language === "jp";
    return (
        <ContactStep className="contact-step" $jp={jp}>
            <div className="step-box">
                <div className="step-icon"><img src={partnerStep1} alt="Photomag partner step1" /></div>
                <strong>{t("common.partner")}</strong>
                <span>{t("partnerPage.coalitionEx1")}<br />{t("partnerPage.coalitionEx2")}</span>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={partnerStep2} alt="Photomag partner step2" /></div>
                <strong>{t("partnerPage.review")}</strong>
                <span className="cs-jp">{t("partnerPage.reviewEx1")}<br />{t("partnerPage.reviewEx2")}</span>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={partnerStep3} alt="Photomag partner step3" /></div>
                <strong>{t("partnerPage.AffiliationOrNot")}</strong>
                <span className="cs-jp">{t("partnerPage.AffiliationOrNotEx1")}<br />{t("partnerPage.AffiliationOrNotEx2")}</span>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={partnerStep4} alt="Photomag partner step4" /></div>
                <strong>{t("partnerPage.contract")}</strong>
                <span>{t("partnerPage.contractEx1")}<br />{t("partnerPage.contractEx2")}</span>
            </div>
        </ContactStep>
    )
}