import styled from "styled-components";
import RootLayoutComponents from "./RootLayoutComponents";
import Header from "./Header";
import Gnb from "./Gnb";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const BodyWrap = styled.div`
    width:100%;
    min-height:100dvh;
    overflow-x:hidden;
    .inner {
        padding:0 20px;
        max-width:1500px;
        width:100%;
        margin-left:auto;
        margin-right:auto;
    }
    @media screen and (max-width: 1440px) {
        .inner {max-width:90%;}
    }

    /* 태블릿 사이즈 (가로 768px 이상 1024px 이하) */
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .header-wrap .hd-contact {display:none;}
        .header-wrap .hd-gnb {gap:10px;flex:none;padding-left:0;}
    }

    /* 모바일 사이즈 (가로 767px 이하) */
    @media screen and (max-width: 767px) {
        .inner {max-width:none;}
        .title {font-size:28px; font-weight:700;}
        .subtitle {font-size:16px;}
        .title-area {margin-bottom:35px !important;}
        .header-wrap .hd-contact {display:none;}
        .header-wrap .hd-gnb {display:none;}
        .header-wrap .btn-menu {display:inline-block;}
        .lang-list {right:42px !important;}
        
        section + section {margin-top:80px !important;}

        .page-header {height:200px !important;padding-bottom:30px;align-items:end !important;margin-bottom:50px !important;}
        .page-header .page-title {font-size:28px !important;}
        .page-header .page-text {font-size:16px !important;font-weight:500 !important;}
    }
    section + section {margin-top:140px;}

    &.sub-layout .header-wrap {background-color:#151515;border-bottom:0;}
    &.sub-layout .menu-wrap {background-color:#151515;}
    &.sub-layout .container {padding-top:62px;}
    &.sub-layout .footer-wrap {margin-top:120px;}

    .page-header {position:relative;height:440px;display:flex;align-items:center;margin-bottom:80px;}
    .page-header-bg {position:absolute;left:0;top:0;bottom:0;right:0;}
    .page-header-bg img {width:100%;height:100%;object-fit:cover;}
    .page-header .inner {z-index: 1;position:relative;}
    .page-header .page-title {font-size:48px;font-weight:600;color:#fff;display:block;margin-bottom:10px;}
    .page-header .page-text {font-size:24px;color:#fff;font-weight:600;text-shadow:1px 1px 15px rgba(0, 0, 0, 0.25);;word-break:keep-all;line-height:1.4;}

    .search {position:relative;font-size:1.6rem;font-weight:400;}
    .search .btn-search {position:absolute;right:0;top:0;height:100%;display:inline-flex;align-items:center;justify-content:center;width:50px;}
    .search .input {width:100%; height:50px; padding:0 60px 0 16px; outline:0; border-radius: 8px;border: 1px solid #5B5B5B; }

    .page-title2 {font-size:36px;font-weight:600;margin-bottom:40px;}

    /* 메인페이지 */
    /* 모바일 사이즈 (가로 767px 이하) */
    @media screen and (max-width: 767px) {
        .visual-contents .title {font-size:34px !important; margin-bottom:10px;}
        .visual-contents .subtitle {font-size:18px; margin-bottom:20px; line-height:1.4;}
        .visual .visual-contents {align-items:end; padding-bottom:40px;}
        .point {margin-bottom:30px;}
        .point-item {padding:10px 16px; font-size:14px;}
        .visual-contents .btn-contact {font-size:16px; padding:10px 24px;}
        .visual .swiper-pagination {left:unset; transform:translateX(0); right:20px;}
        .visual .swiper-pagination {display:none;}
        .focus-slide .swiper-slide {width:56%;}
        .focus-slide .swiper-slide:nth-child(odd) {transform:translateY(46px);}
        .focus-slide {padding-bottom:100px;}
        .line-banner {height:auto;}
        .line-banner-cts {flex-direction:column; justify-content:center; gap:14px; align-items:start; padding:24px 40px;}
        .line-banner-text {font-size:18px;}
        .line-banner-btn {font-size:14px; padding:8px 24px;}
        .store-slide .swiper-slide {width:70%;}
        .special-slide-prev {left:-14px;}
        .special-slide-next {right:-14px;}
        .partnership-box {flex-direction:column-reverse;}
        .pt-left {width:100%;}
        .pt-right {width:100%;}
        .pt-left .radio label {padding:10px 16px;}
        .pt-left .row {flex-direction:column;}
        .pt-left .input-label {font-size:18px;}
    }

    /* 제휴문의, 창업문의, 해외창업문의 상단 이미지 */
    @media screen and (max-width: 1024px) {
        .contact-step {grid-template-columns: repeat(3, 1fr);}
        .contact-step .step-box .step-icon:after {content:none;}
    }

    /* 모바일 사이즈 (가로 767px 이하) */
    @media screen and (max-width: 767px) {
        .contact-kind a {padding:10px 16px;}
        .contact-form {width:100%;}
        .contact-form .row {flex-direction:column;gap:34px;}
        .contact-form .input-label {font-size:18px;}
        .contact-step {grid-template-columns: repeat(2, 2fr);gap:20px 0;}
        .contact-step .step-box .step-icon {width:110px;height:110px;}
        .contact-step .step-box .step-icon img {max-width:50%;max-height: 50%;}
        .contact-step .step-box > strong {font-size:16px;line-height:1.3;}
        .contact-step .step-box > span {font-size:14px;}
        .contact-step .step-box .step-icon:after {content:none;}
    }
    .blind {position:absolute; clip:rect(0 0 0 0); width:1px; height:1px; margin:-1px; overflow:hidden;}
    [class^="material-icons"] {font-size:1.3em; color:inherit;}
    .ta-center {text-align:center;}
`;

export default function MainLayoutComponents({ children }) {
    const location = useLocation();
    const isHomePage = location.pathname === "/";

    const [isMenu, setIsMenu] = useState(false);
    const toggleMenu = () => {
        setIsMenu(!isMenu);
    };

    return (
        <RootLayoutComponents>
            <BodyWrap className={isHomePage ? "" : "sub-layout"}>
                <Header toggleMenu={toggleMenu} setIsMenu={setIsMenu} />

                <Gnb isMenu={isMenu} setIsMenu={setIsMenu} />

                {children}

                <Footer />

            </BodyWrap>
        </RootLayoutComponents>
    )
}