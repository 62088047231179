import { Link } from "react-router-dom";
import styled from "styled-components";
import lineBanner from "../../assets/images/home/line_banner_bg.jpg";
import { useTranslation } from "react-i18next";

const LineBannerWrap = styled.section`
    .title-area {margin-bottom:60px;}
    .title {font-size:36px; font-weight:600; margin-bottom:10px;}
    .subtitle {font-size:26px; font-weight:400;}
    .line-banner {
        position:relative; 
        width:100%; 
        height:175px; 
        overflow:hidden; 
        border-radius:15px;
    }
    .line-banner-bg {
        position:absolute; 
        left:0; top:0; 
        bottom:0; right:0; 
        width:100%; 
        height:100%;
        img {
            width:100%; height:100%; object-fit:cover;
        }
    }
    .line-banner-cts {
        display:flex; 
        justify-content:space-between; 
        padding:0 50px; 
        position:relative; 
        height:100%; 
        align-items:center; 
        width:100%; 
        z-index:3;
    }
    .line-banner-text {
        color:#fff; font-size:26px; font-weight:700; line-height:1.4;
    }
    .line-banner-btn {
        color:#fff; 
        font-size:18px; 
        font-weight:500; 
        border-radius:50px; 
        display:inline-block; 
        padding:12px 24px; 
        border:1px solid #FFF;
        background:rgba(255, 255, 255, 0.15); 
        backdrop-filter:blur(11.449999809265137px);
    }
`;

export default function LineBanner() {
    const { t } = useTranslation();
    return (
        <LineBannerWrap>
            <div className="inner">
                <div className="line-banner">
                    <div className="line-banner-bg"><img src={lineBanner} alt="Photomag lineBanner" /></div>
                    <div className="line-banner-cts">
                        <strong className="line-banner-text">오직 PHOTOMAG에서만<br />경험해 볼 수 있는 이벤트!</strong>
                        <Link to="/inquiry" className="line-banner-btn" target="_blank">자세히 보기</Link>
                    </div>
                </div>
            </div>
        </LineBannerWrap>
    )
}