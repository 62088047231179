import styled from "styled-components";
// import item1 from "../../assets/images/home/grid_1.png";
import item1 from "../../assets/images/home/grid_1.jpg";
import item2 from "../../assets/images/home/grid_2.png";
import item3 from "../../assets/images/home/grid_3.png";
import item4 from "../../assets/images/home/grid_4.png";
import CmmnRadio from "../../cmmn/CmmnRadio";
import { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import CmmnForm from "../../cmmn/CmmnForm";
import { callApi } from "../../util/HttpService";
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { formatContentWithParagraphs } from "../../util/CmmnUtils";

const PartnershipWrap = styled.section`
    .tab-pane { display: none; }
    .tab-pane.active { display: block; }
    .fade { opacity: 0; transition: opacity 0.2s ease-in-out; }
    .fade.show { opacity: 1; }
    .title-area {margin-bottom:60px;}
    .title {font-size:36px; font-weight:600; margin-bottom:10px;}
    .subtitle {font-size:26px; font-weight:400;}
    margin-bottom:120px;
    .partnership-box {display:flex; gap:40px;}
    .pt-right { 
        width:60%;
        .grid-img {
            display:grid; width:100%; gap:5px;
            img {width:100%; height:100%; object-fit:cover;}
            .item1 {grid-row:1 / 4; grid-column:1 / 2;}
            .item2 {grid-column:2 / 3; grid-row:1 / 2;}
            .item3 {grid-column:2 / 3; grid-row:2 / 3;}
            .item4 {grid-column:2 / 3; grid-row:3 / 4;}
        }
    }
`;
const PtLeft = styled.div`
    width:40%;
    @media screen and (max-width: 767px) {
        label {
            padding: ${props => props.$language === "jp" ? "10px 9px 10px !important" : "10px 12px 10px !important"};
            font-size: ${props => props.$language === "jp" ? "14px !important" : "15px !important"}; 
        }
    }
`;

export default function Partnership() {

    const { t, i18n } = useTranslation();

    // validation
    // const validationSchema = yup.object().shape({
    //     agreeStatus: yup.object().test(
    //         'agreeStatusTest',
    //         '개인정보 수집 및 이용방침 동의를 체크해주세요.',
    //         function (value) {
    //             return value && Object.values(value).some((status) => status === true);
    //         }
    //     ),

    // });
    const validationSchema = yup.object().shape({
        iqryTypeCd: yup.string().required(),
        coNm: yup.string().test(
            'coNmTest',
            `${t("common.coNm")}`,
            function (value) {
                const { iqryTypeCd } = this.parent;
                if (iqryTypeCd !== 'FUIQ' && !value) {
                    return false;
                }
                return true;
            }
        ),
        iqrrNm: yup.string().test(
            'iqrrNmTest',
            function (value) {
                const { iqryTypeCd } = this.parent;
                if (!value) {
                    return this.createError({
                        message: iqryTypeCd === 'FUIQ' ? `${t("common.name")}` : `${t("common.manager")}`,
                    });
                }
                return true;
            }
        ),
        founZoneNm: yup.string().test(
            'founZoneNmTest',
            `${t("common.fz")}`,
            function (value) {
                const { iqryTypeCd } = this.parent;
                if (iqryTypeCd === 'FUIQ' && !value) {
                    return false;
                }
                return true;
            }
        ),
        iqrrTelNo: yup.string().required(`${t("common.tel")}`),
        iqrrEmalAddr: yup.string().required(`${t("common.email")}`),
    });

    const partMenu = [
        { label: t("common.partner"), value: "AFIQ" },
        { label: t("common.startup"), value: "FUIQ" },
        { label: t("common.abroad"), value: "GFIQ" },
    ];

    const defaultIqryInfo = {
        iqryTypeCd: partMenu[0].value,
        coNm: "",
        iqrrNm: "",
        iqrrTelNo: "",
        iqrrEmalAddr: "",
        iqryCn: "",
        founZoneNm: "",
    };
    const [iqryInfo, setIqryInfo] = useState(defaultIqryInfo);
    const [errors, setErrors] = useState({});
    const [afiqFile, setAfiqFile] = useState(null);
    const [gfiqFile, setGfiqFile] = useState(null);
    const [activeKey, setActiveKey] = useState(partMenu[0].value);
    // const [agreeStatus, setAgreeStatus] = useState({ AFIQ: false, FUIQ: false, GFIQ: false });

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        const formattedTelNo = name === "iqrrTelNo" ? value.replace(/[^0-9]/g, "") : value;
        setIqryInfo((prevData) => ({
            ...prevData,
            [name]: formattedTelNo,
        }));
        if (name === "iqryTypeCd") {
            setActiveKey(formattedTelNo);
            // setAgreeStatus({ AFIQ: false, FUIQ: false, GFIQ: false });
            setIqryInfo({ ...defaultIqryInfo, iqryTypeCd: formattedTelNo });
            setErrors({});
            setAfiqFile(null);
            setGfiqFile(null);
        }
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (formattedTelNo !== '') {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleAfiqFileSelect = (selectedFile) => {
        setAfiqFile(selectedFile);
    };
    const handleGfiqFileSelect = (selectedFile) => {
        setGfiqFile(selectedFile);
    };

    // const handleCheckboxChange = (event, type) => {
    //     const checked = event.target.checked;
    //     setAgreeStatus((prevState) => {
    //         const updatedStatus = {
    //             ...prevState,
    //             [type]: checked,
    //         };
    //         if (checked) {
    //             setErrors((prevErrors) => {
    //                 const newErrors = { ...prevErrors };
    //                 delete newErrors.agreeStatus;
    //                 return newErrors;
    //             });
    //         }
    //         return updatedStatus;
    //     });
    // };

    // useEffect(() => {
    //     console.log(iqryInfo);
    // }, [iqryInfo]);

    const iqryInfoSave = async () => {
        try {

            await validationSchema.validate(iqryInfo, {
                abortEarly: false,
                context: { iqryTypeCd: iqryInfo.iqryTypeCd }
            });

            const formattedIqryCn = formatContentWithParagraphs(iqryInfo.iqryCn);

            const formData = new FormData();
            const fileToUpload = iqryInfo.iqryTypeCd === 'AFIQ' ? afiqFile : gfiqFile;

            formData.append('file', fileToUpload);
            formData.append('dirName', 'image');
            formData.append('iqryInfo', new Blob([JSON.stringify({ ...iqryInfo, iqryCn: formattedIqryCn })], { type: 'application/json' }));

            await callApi("POST", formData, "/web/api/public/save", {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(res => {
                    console.log(res);
                    alert(t("alert.iqrySuccess"));
                    setIqryInfo(defaultIqryInfo);
                    setActiveKey(partMenu[0].value);
                    setAfiqFile(null);
                    setGfiqFile(null);
                    // setAgreeStatus({ AFIQ: false, FUIQ: false, GFIQ: false }); // 상태 초기화
                })
                .catch(err => {
                    console.log(err);
                    alert(t("alert.iqryFail"));
                });
        } catch (err) {
            const newErrors = {};
            if (err.inner && err.inner.length > 0) {
                err.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
            } else {
                newErrors[err.path] = err.message;
            }
            setErrors(newErrors);
        }
    };

    return (
        <PartnershipWrap>
            <div className="inner">
                <div className="title-area">
                    <h2 className="title">{t("mainPage.inquriyTitle")}</h2>
                </div>
                <div className="partnership-box">
                    <PtLeft className="pt-left" $language={i18n.language}>
                        <CmmnRadio
                            dataArray={partMenu}
                            onSelectChange={handleFormDataChange}
                            defaultVal="AFIQ"
                            tagName="iqryTypeCd"
                        />
                        <Tab.Container activeKey={activeKey}>
                            <Tab.Content>
                                <Tab.Pane eventKey="AFIQ">
                                    <CmmnForm
                                        id={`afiq_${iqryInfo.iqryTypeCd}`}
                                        formData={iqryInfo}
                                        onFormDataChange={handleFormDataChange}
                                        // onCheckboxChange={(e) => handleCheckboxChange(e, activeKey)}
                                        // checked={agreeStatus[activeKey]}
                                        onFileInfoUpdate={handleAfiqFileSelect}
                                        fileName={afiqFile?.name || ""}
                                        onSubmit={iqryInfoSave}
                                        errors={errors}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="FUIQ">
                                    <CmmnForm
                                        formData={iqryInfo}
                                        onFormDataChange={handleFormDataChange}
                                        // onCheckboxChange={(e) => handleCheckboxChange(e, activeKey)}
                                        // checked={agreeStatus[activeKey]}
                                        onSubmit={iqryInfoSave}
                                        errors={errors}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="GFIQ">
                                    <CmmnForm
                                        id={`gfiq_${iqryInfo.iqryTypeCd}`}
                                        formData={iqryInfo}
                                        onFormDataChange={handleFormDataChange}
                                        // onCheckboxChange={(e) => handleCheckboxChange(e, activeKey)}
                                        // checked={agreeStatus[activeKey]}
                                        onFileInfoUpdate={handleGfiqFileSelect}
                                        fileName={gfiqFile?.name || ""}
                                        onSubmit={iqryInfoSave}
                                        errors={errors}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </PtLeft>
                    <div className="pt-right">
                        <div className="grid-img">
                            <div className="item1"><img src={item1} alt="Photomag partnership item1" /></div>
                            <div className="item2"><img src={item2} alt="Photomag partnership item2" /></div>
                            <div className="item3"><img src={item3} alt="Photomag partnership item3" /></div>
                            <div className="item4"><img src={item4} alt="Photomag partnership item4" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </PartnershipWrap>
    )
}