import './App.css';
import { Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import MainLayoutComponents from './layout/MainLayoutComponents';
import Store from './pages/store/Store';
import StoreView from './pages/store/detail/Store-view';
import Brand from './pages/brand/Brand';
import Inquiry from './pages/inquirys/Inquiry';
import Product from './pages/product/Product';
import Gallery from './pages/gallery/Gallery';
import Board from './pages/board/Board';
import BoardDtl from './pages/board/detail/Board-view';
import { useEffect } from 'react';
import MetaTag from "./layout/MetaTag";

function App() {

  useEffect(() => {
    document.oncontextmenu = function(){
      return false;
    }
  },[]);
  
  return (
    <MainLayoutComponents>
      <MetaTag />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/inquiry/:type" element={<Inquiry />} />
        <Route path="/store" element={<Store />} />
        <Route path="/store/store-view/:pstsSqno" element={<StoreView />} />
        <Route path="/brand" element={<Brand />} />
        <Route path="/product" element={<Product />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/board" element={<Board/>} />
        <Route path="/board/board-view/:pstsSqno" element={<BoardDtl/>} />
      </Routes>
    </MainLayoutComponents>
  )
}

export default App;
