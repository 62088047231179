import Visual from "./main-sections/Visual";
import Focus from "./main-sections/Focus";
import LineBanner from "./main-sections/LineBanner";
import Special from "./main-sections/Special";
import Store from "./main-sections/Store";
import Partnership from "./main-sections/Partnership";
import { useRef } from "react";
import MetaTag from "../layout/MetaTag";

export default function Main() {

    const spcPrevRef = useRef(null);
    const spcNextRef = useRef(null);

    const strPrevRef = useRef(null);
    const strNextRef = useRef(null);

    return (
        <>
            <MetaTag 
                title="Photomag"
                description="Photomagで雑誌のような写真で素敵な瞬間を撮ってみましょう。"
                keywords="home"
            />
            <div className="container">

                <Visual />

                <Focus />
                {/* 이벤트가 있으면 주석 해제 */}
                {/* <LineBanner /> */}

                <Special prevRef={spcPrevRef} nextRef={spcNextRef} />

                <Store prevRef={strPrevRef} nextRef={strNextRef} />

                <Partnership />

            </div>
        </>
    )
}